import './App.scss';

function App() {
  return (
    <div>
      <div id="header">
        <div id="header-title">Geottuse | Simplify Software</div>
      </div>
      <div id="main">
        <div id="masthead">
          <h6 id="info-header">
            Our mission is to EMPOWER the WORLD and<br/>FUTURE using TECH
            <br/><br/><br/>
            We have a key focus of building simplicity and flexibility into products and our mission is to translate technology to everybody in the world in the most simplest way
          </h6>
        </div>
        <div id="prevgen">
          <div id="prevgen-header">
            <div class="column">
              We designed our products to be so simple that people of all ages, including seniors, can easily learn and enjoy using them
            </div>
          </div>
          <div id="prevgen-image">
            <img alt="prevgenimage" src="/prevgen.jpg"/>
          </div>
        </div>
        <div id="getupdates">
          <div id="getupdates-header">
            Our first product is coming soon
          </div>
        </div>
      </div>
      <div id="footer">
        <div id="footer-header">@ {new Date().getFullYear()} Geottuse, Inc. All Rights Reserved</div>
      </div>
    </div>
  );
}

export default App;
